import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import { BsWhatsapp } from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";

const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        // console.log(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        // alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
        console.log(res.data);
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    Cashheader();
    fetchData();
    //eslint-disable-next-line
  }, []);

  const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyLink = (e) => {
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Referal Link Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  const referralText = `${WebSitesettings.WebsiteName} पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
 दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 3% कमीशन दिया जाएगा  
  
    100% भरोसेमंद प्लेटफार्म। 24 hours support https://A2zludo.in/login/${user.referral_code}
  
  Your ReferralCode is - ${user.referral_code}.`;

  return (
    <>
      <Header user={user} />
      
      <div>
        <div
          className="leftContainer mb_space"
          style={{ minHeight: "100vh", height: "100%" }}
        >
          <section className="refer_page_main_section">
            <div className="refer_page_top_main_box_outer mb-5">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">Your Referral Earnings</h2>
              </div>
              <div className="refer_page_top_main_box_inner">
                <div className="refer_page_top_main_box_inner_single bo_le">
                  <h3>Referred Players</h3>
                 <h4>
               {cardData&&cardData}
              </h4>
                </div>
                <div className="refer_page_top_main_box_inner_single">
                  <h3>Referral Earning</h3>
                  <h4>
                    <span>₹</span>
                    {user.referral_earning}
                  </h4>
                </div>
              </div>
            </div>

            <div className="refer_page_top_main_box_outer">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">Referral Code</h2>
              </div>
              <div>
                <div className="Refer_code">
                  <h3>{user.referral_code}</h3>

                  <button onClick={(e) => copyCode(e)}>Copy</button>
                </div>
                <div className="or_div">
                  {" "}
                  <h2>OR</h2>{" "}
                </div>
                <div className="whatsapp_share_btn">
                  <a
                    href={`whatsapp://send?text=Play  A2zludo Ludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
                    दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 3% कमीशन दिया जाएगा  
                      100% भरोसेमंद प्लेटफार्म। 24 hours support https://A2zludo.in/login/${user.referral_code}
                    Your ReferralCode is - ${user.referral_code} ..https://A2zludo.in/login/${user.referral_code}Register Now, My refer code is ${user.referral_code}.`}
                    target="_blank"
                  >
                    <BsWhatsapp className="icons" />
                  </a>

                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=#url?text=Play  A2zludo Ludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
                    दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 3% कमीशन दिया जाएगा  
                      100% भरोसेमंद प्लेटफार्म। 24 hours support https://A2zludo.in/login/${user.referral_code}
                    Your ReferralCode is - ${user.referral_code} ..https://A2zludo.in/login/${user.referral_code}Register Now, My refer code is ${user.referral_code}.`}
                    target="_blank"
                  >
                    {" "}
                    <AiFillFacebook className="icons" />
                  </a>

                  {/* <a
                    href={`https://telegram.me/share/url?url?text=Play  A2zludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 3% कमीशन दिया जाएगा  

  100% भरोसेमंद प्लेटफार्म। 24 hours support.https://A2zludo.in//login/${user.referral_code}Register Now, My refer code is ${user.referral_code}.`}
                    target="_blank"
                  >
                    <FaTelegramplane className="icons" />
                  </a> */}

                  <CopyToClipboard text={referralText}>
                    <button
                      className="bg-green refer-button cxy bg3 "
                      onClick={(e) => copyLink(e)}
                    >
                      <FiCopy className="icons" />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <div className="refer_page_top_main_box_outer mt-5">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">How It Works</h2>
              </div>
              <div className="refer_page_top_main_box_inner">
                <div className="refer_rules">
                  <p>
                    1. You can refer and Earn 3% of your referral winning, every
                    time
                  </p>
                  <p>
                    2. Like if your player plays for More Amount. 10000 and
                    wins, You will get Ruppe. 300 as referral amount.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Refer;
